import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';

const Content = () => (
  <>
    <p>
      By importing the <strong>Footer</strong> component from
      gatsby-theme-carbon, we can supply our own props.
    </p>
    <p>
      The default export from a shadowed component will replace that component
      in the theme.
    </p>
    <p>
      <a
        href="https://www.gatsbyjs.org/docs/themes/api-reference/#component-shadowing"
        target="blank"
      >
        More about component shadowing
      </a>
    </p>
  </>
);

const links = {
  firstCol: [
    // Social Links
    { href: 'https://www.linkedin.com/in/garciaericn/', linkText: 'LinkedIn' },
    { href: 'https://www.facebook.com/garciaericn', linkText: 'Facebook' },
    { href: 'https://twitter.com/garciaericn', linkText: 'Twitter' },
    { href: 'https://www.instagram.com/eng618/', linkText: 'Instagram' },
  ],
};

const CustomFooter = () => <Footer links={links} Content={Content} />;

export default CustomFooter;
